import React, { useEffect, useState } from 'react';
import { MdArrowBackIosNew, MdArrowForwardIos  } from "react-icons/md";


function Carrusel() {

  const images = [
    `/img/Carrusel/1.webp`,
    '/img/Carrusel/2.webp',
    '/img/Carrusel/3.webp',
    '/img/Carrusel/4.webp',
    '/img/Carrusel/5.webp',
    '/img/Carrusel/6.webp',
    '/img/Carrusel/7.webp',
    '/img/Carrusel/8.webp',
    '/img/Carrusel/9.webp'
  ];

  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedImage, setSelectedImage] = useState(images[0]);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
      const interval = setInterval(() => {
        selectNewImage(selectedIndex, images, true);
      }, 5000);
      return () => clearInterval(interval);
  });

  const selectNewImage = (index, images, next) => {
    setLoaded(false);
    setTimeout(() => {
      const condition = next ? selectedIndex < images.length - 1 : selectedIndex > 0;
      console.log(condition);
      const nextIndex = next ? (condition ? selectedIndex + 1 : 0) : condition ? selectedIndex - 1 : images.length - 1;
      setSelectedImage(images[nextIndex]);
      setSelectedIndex(nextIndex);
    }, 500);
  };

  const previous = () => {
    selectNewImage(selectedIndex, images, false);
  };

  const next = () => {
    selectNewImage(selectedIndex, images, true);
  };

    const goToSlide = (slideIndex) => {
      setSelectedImage(images[slideIndex]);
      setSelectedIndex(slideIndex);
    //setAutoPlay(false);
  };

  return (
    <>
      <div className='carrusel-container'>
        <img
          src={selectedImage}
          alt="Famox"
          className={loaded ? "loaded" : ""}
          onLoad={() => setLoaded(true)}
        />
        <div>
          <button className='leftArrowStyle' onClick={previous}><MdArrowBackIosNew/></button>
          <button className='rightArrowStyle' onClick={next}><MdArrowForwardIos/></button>
        </div>
      </div>
      <div className='dotsContainerStyles'>
          {images.map((slide, slideIndex) => (
            <div
              className={slideIndex === selectedIndex ? 'dotStyleOn':'dotStyleOff'}
              key={slideIndex}
              onClick={() => goToSlide(slideIndex)}
            >
            ●
            </div>
            ))}
        </div>
    </>
  )
}

export default Carrusel;